import { EN_DASH } from "@digitallab/grid-common-components";
import { DateTimeDetailValue } from "../../../../components/shared/details-cells/DateTimeDetailValue";
import DetailsCells from "../../../../components/shared/details-cells/DetailsCells";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import ActionLogFileList from "../../ActionLogFileList";
import ActionLogLinkDetailView from "../../ActionLogLinkDetailView";
import {
  ApproverUserName,
  CustomDetailsCellStyled,
  Flex,
  RunLogWrapper,
  SystemStatusCell,
  GxpReadyCell,
  ActionTypeCell
} from "../../common-for-log-sheet-and-run-logs/table-common-meta-info";
import { changeDateFormat } from "../../helpers";

const actionTimeField = {
  label: ACTION_LOGS_DATA_MODEL.actionTime.value,
  component: ({ item }) => <DateTimeDetailValue time={item?.actionTime} />
};

export const AttachmentDocs = {
  lookapVal: "attachments",
  field: ["attachments"],
  props: {
    style: { width: "100%" }
  },
  component: ({ item }) => <ActionLogFileList attachments={item.attachments} />
};

const descriptionField = {
  label: COMMON_LOGS_DATA_MODEL.fullDescription.value,
  field: ["description"],
  props: {
    expand: true
  }
};
const externalDocs = {
  field: ["externalDocument"],
  props: {
    style: { width: "625px" }
  },
  component: ({ item }) => {
    return <ActionLogLinkDetailView externalDocuments={item?.externalDocument} />;
  }
};

export const LogSheetTableMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        {
          label: ACTION_LOGS_DATA_MODEL.ecrNumber.value,
          field: ["ecrNumber"]
        },
        {
          label: ACTION_LOGS_DATA_MODEL.gxpReady.value,
          field: ["gxpReady"]
        },
        {
          label: ACTION_LOGS_DATA_MODEL.systemStatus.value,
          field: ["systemStatus"]
        },
        actionTimeField,
        descriptionField,
        {
          label: "Configuration details",
          field: ["configurationDetails"],
          props: {
            expand: true,
            style: { width: "100%" }
          },
          component: ({ item }) => <div style={{ whiteSpace: "pre-wrap" }}>{item.configurationDetails || EN_DASH}</div>
        },
        externalDocs
      ]
    }
  },
  fields: {
    detailExpander: {
      text: ""
    },
    actionDate: {
      sortable: true,
      text: ACTION_LOGS_DATA_MODEL.actionDate.value,
      component: ({ item }) => changeDateFormat(item?.actionDate)
    },
    equipmentModel: {
      text: COMMON_LOGS_DATA_MODEL.equipmentModel.value
    },
    equipmentNickName: {
      text: COMMON_LOGS_DATA_MODEL.equipmentNickName.value
    },
    equipmentId: {
      text: COMMON_LOGS_DATA_MODEL.equipmentId.value
    },
    serialNumber: {
      text: COMMON_LOGS_DATA_MODEL.serialNumber.value
    },
    action: {
      text: ACTION_LOGS_DATA_MODEL.actionType.value,
      component: ActionTypeCell
    },
    ...ApproverUserName,
    operatorUserName: {
      text: COMMON_LOGS_DATA_MODEL.operatorUserName.value
    },
    ecrNumber: {
      text: ACTION_LOGS_DATA_MODEL.ecrNumber.value
    },
    gxpReady: {
      text: ACTION_LOGS_DATA_MODEL.gxpReady.value,
      component: GxpReadyCell
    },
    systemStatus: {
      text: ACTION_LOGS_DATA_MODEL.systemStatus.value,
      component: SystemStatusCell
    },
    description: {
      text: COMMON_LOGS_DATA_MODEL.description.value
    }
  }
};

export const logSheetTabletSizes = {
  actionDate: "0 1 12.75%",
  equipmentId: "0 1 15%",
  serialNumber: "0 1 15%",
  equipmentModel: "0 1 12.75%",
  action: "0 1 20%",
  approverUserName: "0 1 5%",
  gxpReady: "0 1 10%",
  systemStatus: "0 1 12.75%",
  actionButtons: "0 0 110px"
};

export const logSheetRegularSizes = {
  actionDate: "0 1 15%",
  equipmentId: "0 1 15%",
  serialNumber: "0 1 15%",
  equipmentModel: "0 1 15%",
  action: "0 1 18%",
  approverUserName: "0 1 5%",
  gxpReady: "0 1 10%",
  systemStatus: "0 1 15%",
  actionButtons: "0 0 110px"
};

export const LogSheetTableEquipmentCentricMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        {
          label: ACTION_LOGS_DATA_MODEL.ecrNumber.value,
          field: ["ecrNumber"]
        },
        {
          label: ACTION_LOGS_DATA_MODEL.gxpReady.value,
          field: ["gxpReady"]
        },
        {
          label: ACTION_LOGS_DATA_MODEL.systemStatus.value,
          field: ["systemStatus"]
        },
        actionTimeField,
        descriptionField,
        {
          label: ACTION_LOGS_DATA_MODEL.systemStatus.value,
          field: ["systemStatus"]
        },
        {
          label: "Configuration details",
          field: ["configurationDetails"],
          props: {
            expand: true,
            style: { width: "100%" }
          },
          component: ({ item }) => <div style={{ whiteSpace: "pre-wrap" }}>{item.configurationDetails || EN_DASH}</div>
        },
        externalDocs
      ]
    }
  },
  fields: {
    detailExpander: {
      text: ""
    },
    actionDate: {
      sortable: true,
      text: ACTION_LOGS_DATA_MODEL.actionDate.value,
      component: ({ item }) => changeDateFormat(item?.actionDate)
    },
    action: {
      text: ACTION_LOGS_DATA_MODEL.actionType.value,
      component: ActionTypeCell
    },
    ...ApproverUserName,
    operatorUserName: {
      text: COMMON_LOGS_DATA_MODEL.operatorUserName.value
    },
    ecrNumber: {
      text: ACTION_LOGS_DATA_MODEL.ecrNumber.value
    },
    gxpReady: {
      text: ACTION_LOGS_DATA_MODEL.gxpReady.value,
      component: GxpReadyCell
    },
    systemStatus: {
      text: ACTION_LOGS_DATA_MODEL.systemStatus.value,
      component: SystemStatusCell
    },
    description: {
      text: COMMON_LOGS_DATA_MODEL.description.value
    }
  }
};

export default LogSheetTableMeta;
